import {React, useState} from 'react';
import { Grid, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  div: {
    padding: '70% 30px'
  },
	gridContainer: {
		height: '200px',
		backgroundColor: 'red',
		borderRadius: 10
  },
	gridItemDiv: {
		backgroundColor: 'white',
		width: '100%',
		textAlign: 'center'
	}
}));

const SdcTech = () => {
  const classes = useStyles();
	const [items, setItems] = useState(["Item 1", "Item 2"]);

	function StyleGrid() {
		return (
			<Grid className={classes.gridContainer} container justifyContent="center" alignItems="center" spacing={2}>
				{items.map((value) => (
					<Grid key={value} item xs={4}>
						<div className={classes.gridItemDiv}>
							{value}
						</div>
					</Grid>
				))}
			</Grid>
		);
	}

  return (
    <div className={classes.div}>
			<StyleGrid />
    </div>
  );
};

export default SdcTech;
