import React, { useState } from 'react';
import Message from './Message';
import axios from 'axios';
import './Message.css';

const MessagesContainer = ({user, vehicleTypeId, companyId, modelId}) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (msg, type) => {
    setMessages((prevMessages) => [...prevMessages, { msg, type }]);
  };

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value); // Update the message state with the input value
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (message.trim()) {
      createMessage(message)
      setMessage(''); // Clear the input field after sending
    }
  };

  React.useEffect(() => {
    const fetchMessages = async () => {
      const response = await axios.get(`${process.env.REACT_APP_RAILS_API}/api/v1/vehicle_types/${vehicleTypeId}/companies/${companyId}/models/${modelId}/messages`);
      setMessages(response.data);
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [message]);
	
	const createMessage = async (content) => {
		const response = await axios.post(
			`${process.env.REACT_APP_RAILS_API}/api/v1/vehicle_types/${vehicleTypeId}/companies/${companyId}/models/${modelId}/messages`,
			{ message: { content, email: user.email} }, {
        headers: {
          'Accept': 'application/json',
          // Content-Type is automatically set for FormData, so you can omit it
        },
      }
		);
		return response.data;
	};

  return (
    <div>
      <div className="messages-container">
        {messages.map((message, index) => (
          <Message
            key={index}
            message={message.content}
            mymessage={user.id === message.user_id}
          />
        ))}
      </div>
      <div className="messages-text-area">
        <form onSubmit={handleSubmit}>
          <textarea
            value={message}
            onChange={handleChange}
            placeholder="Type your message here..."
            rows="4"
            cols="50"
            style={{ width: '100%', padding: '10px', borderRadius: '5px' }}
          />
          <button type="submit" style={{ marginTop: '10px' }}>Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default MessagesContainer;
