// src/store/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')) || null, // Load user from localStorage
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload)); // Save user to localStorage
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem('user'); // Remove user from localStorage on logout
    },
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;
