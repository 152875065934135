// src/store/reducers/index.js
import { combineReducers } from 'redux';

import userReducer from '../slices/userSlice';

// Combine all reducers
const rootReducer = combineReducers({
  user: userReducer,
});

export default rootReducer;
