// Message.js
import React from 'react';
import './Message.css'; // Add your own styles

const Message = ({ message, mymessage }) => {

  return (
    <div className={`message ${mymessage ? 'mymessage-style' : ''}`}>
      <p className={`message-content ${mymessage ? 'mymessage-content-style' : ''}`}>{message}</p>
    </div>
  );
};

export default Message;
