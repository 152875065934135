import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core';
import axios from 'axios';
import DrawerLayout from '../DrawerLayout';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const windowInnerWidth = window.innerWidth;

const useStyles = makeStyles({
  cardContent: {
    height: '64vh',
    overflow: 'scroll',
    margin: '24px',
    padding: '0px',
    wordBreak: 'break-word'
  },
  backButton: {
    position: 'absolute',
    // top: theme.spacing(2),
    // left: theme.spacing(2),
    zIndex: 1, // Ensures the button is on top of the image
    // color: theme.palette.common.white, // To make the button visible on top of the image
    color: 'white'
  }
});

function Blog(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_RAILS_API}/api/v1/blogs/${id}`);
        setSelectedBlog(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlog();
  }, [id]); // add id to the dependency array to re-fetch when it changes

  // Early return if selectedBlog is null or still loading
  if (!selectedBlog) {
    return (
      <DrawerLayout>
        <Typography>Loading...</Typography>
      </DrawerLayout>
    );
  }

  const fullImageUrl = `${process.env.REACT_APP_RAILS_API}${selectedBlog?.image_url}`;

  return (
    <DrawerLayout>
      <Card key={selectedBlog.id} className={classes.card}>
        <IconButton className={classes.backButton} onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <CardMedia
          component="img"
          height="194"
          image={fullImageUrl}
          alt="Image"
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.contentText} variant="body2" color="text.secondary">
            {selectedBlog.content}
          </Typography>
        </CardContent>
      </Card>
    </DrawerLayout>
  );
}

export default Blog;
