// routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SdcTech from './pages/SdcTech';
import PrivateRoute from './PrivateRoute';
import Blog from './pages/blog/Blog';

const routes = (
  <Routes>
    <Route path="/signin" element={<SignIn />} />
    <Route path="/sdctech" element={<SdcTech />} />
    <Route
      path="/"
      element={
        <PrivateRoute element={<Home />} />
      }
    />
    <Route
      path="/home"
      element={
        <PrivateRoute element={<Home />} />
      }
    />
    <Route
      path="/blog/:id"
      element={
        <PrivateRoute element={<Blog />} />
      }
    />
  </Routes>
);

export default routes;
